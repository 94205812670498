// import axios from 'axios'
// import store from './store/'
// import router from './config/routes'

// import Qs from 'qs'
// window.BASE_URL='http://192.168.3.170:8000/'

// const service = axios.create({
//   baseURL: window.BASE_URL, // api的base_url
//   timeout: 5000 // 请求超时时间
// baseURL: 'https://m.qkktrip.com/', http://api.glook.net/

// }),
export default {
  method: 'GET',
  baseURL: '',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Accept': 'application/json'
  },
  params: {},
  timeout: 50000,
  withCredentials: true,
  responseType: 'json',
  maxContentLength: 2000,
  validateStatus: status => status >= 200 && status < 300,
  maxRedirects: 5,
  // transformRequest: [data => Qs.stringify(data, { arrayFormat: 'indices' })],
  // paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'indices' }),
}
