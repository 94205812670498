import axios from 'axios'
// import store from '@/store'
import config from './config.js'
// import router from '@/router'
// import CryptoJS from 'crypto-js'



let instance = axios.create(config)



// http request 拦截器
instance.interceptors.request.use(async config => {

        config.headers.Authorization = 'Bearer ' + 'sdfsdfsdfsdfsdfsdf';
        // console.log(config)
    return config
}, error => {
    return Promise.reject(error)
});


// http response 拦截器
instance.interceptors.response.use(response => {

    return response
}, error => {
    // token过期 请求授权失败
    if (error.response.status === 401) {
        // loginFailed();
        // console.log('token过期，重新登录')
    }
    return Promise.reject(error.response.data)
});

export default instance
